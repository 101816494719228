import { getCookie } from '@/utils/cookies';

// CSRF token
const CsrfTokenCookieName = 'CSRF-TOKEN';
const getCsrfTokenCookie = (): string => getCookie<string>(CsrfTokenCookieName) || '';

// API token
const ApiTokenCookieName = 'expiring_api_token';
const getApiTokenCookie = (): string => getCookie<string>(ApiTokenCookieName) || '';

export { getCsrfTokenCookie, getApiTokenCookie };
