import { QueryClient, QueryFunction, QueryKey, useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import axios from 'axios';

type QueryDef<T> = { key: QueryKey; fn: QueryFunction<T>; opts?: Omit<UseQueryOptions<T>, 'queryKey' | 'queryFn'> };
type AngularQueryFunctionArgs = { cancel?: Promise<void>; reload?: boolean };
type AngularQueryFunction<T> = (args?: AngularQueryFunctionArgs) => Promise<T>;

export const defaultQueryRetryCount = 3;
const httpStatusesNotToRetry = [400, 401, 403, 404, 405, 410, 429];

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: (failureCount, error) => {
        if (failureCount > defaultQueryRetryCount) {
          return false;
        }
        if (axios.isAxiosError(error) && httpStatusesNotToRetry.includes(error.response?.status ?? 0)) {
          console.log(`Aborting retry due to ${error.response?.status} status`);
          return false;
        }

        return true;
      },
    },
  },
});

export function makeQuery<T>(
  key: QueryKey,
  fn: QueryFunction<T>,
  opts?: Omit<UseQueryOptions<T>, 'queryKey' | 'queryFn'>,
): QueryDef<T> {
  return { fn, key, opts };
}

export function toAngular<T>(def: QueryDef<T>): AngularQueryFunction<T> {
  return ({ cancel, reload } = {}) => {
    cancel?.then(() => queryClient.cancelQueries({ exact: true, queryKey: def.key }));
    if (reload) {
      queryClient.invalidateQueries({ exact: true, queryKey: def.key });
    }
    return queryClient.fetchQuery({ queryFn: def.fn, queryKey: def.key, ...def.opts });
  };
}

export function toReact<T>(def: QueryDef<T>): () => UseQueryResult<T> {
  return () => useQuery({ queryFn: def.fn, queryKey: def.key, ...def.opts });
}
