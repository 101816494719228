import Cookies, { CookieAttributes } from 'js-cookie';

type CookieValueDeserializer<T> = (value: string, name: string) => T;
type CookieValueSerializer<T> = (value: T, name: string) => string;

const getCookie = <T = string>(name: string, deserializer?: CookieValueDeserializer<T>): T | null => {
  const value = Cookies.get(name);

  if (value === undefined || value === null) {
    return null;
  }

  return deserializer ? deserializer(value, name) : (value as unknown as T);
};

const setCookie = <T = string>(
  name: string,
  value: T | null,
  options?: {
    serializer?: CookieValueSerializer<T>;
    cookieOptions?: CookieAttributes;
  },
): void => {
  if (value === null) {
    Cookies.remove(name, options);
  } else {
    const serializedValue = options?.serializer ? options?.serializer(value, name) : String(value);
    Cookies.set(name, serializedValue, options?.cookieOptions);
  }
};

const removeCookie = (name: string, cookieOptions?: CookieAttributes): void => {
  Cookies.remove(name, cookieOptions);
};

const getAndRemoveCookie = <T = string>(
  name: string,
  options?: {
    deserializer?: CookieValueDeserializer<T>;
    cookieOptions?: CookieAttributes;
  },
): T | null => {
  const result = getCookie(name, options?.deserializer);
  removeCookie(name, options?.cookieOptions);
  return result;
};

export { getCookie, setCookie, removeCookie, getAndRemoveCookie };
