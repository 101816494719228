export type Environment = {
  BITRISE_API_SERVER_URL_FOR_FRONTEND: string;
  BITRISE_WEBSITE_LANDING_ROOT_URL: string;
  DATADOG_APPLICATION_ID: string;
  DATADOG_CLIENT_TOKEN: string;
  PRODUCTION: boolean;
  BITRISE_INSIGHTS_URL: string;
  WEBSITE_RELEASE_NUMBER: string;
  SEGMENT_JS_WRITE_KEY_NEW: string;
};

const ENVIRONMENT = window.globalProps.env;
export default ENVIRONMENT;
